import axios from 'axios';
import { makeAutoObservable } from 'mobx';
import { apiImprt } from 'utils';
import { apiIdentity } from 'utils';

import { IMediumResponse } from '../utils/const'

class AssetsStore {
  assets = [];
  totalItems = 0;
  totalPages = 0;

  constructor() {
    makeAutoObservable(this);
  }

  async getMarketplaceAssets() {
    this.marketplaceAssets = await axios
      .get(
        'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@somniumspace'
      )
      .then(e => e?.data)
  }

  async getMarketplaceAsset() {
    this.marketplaceAsset = await axios
      .get(
        'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@somniumspace'
      )
      .then(e => e?.data)
  }

  async getUserProfileInfo(userId: string) {
    try {
      const response = await apiIdentity.get(`/api/Account/userProfileInfo?userId=${userId}`);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.data?.Message || 'Failed to fetch user profile info');
      }
    } catch (error) {
      console.error('Error in getUserProfileInfo:', error);
      throw error;
    }
  }

  async getCommunityAssetItems(requestData: {
    PageNumber: number;
    PageSize: number;
    SearchText: string;
    Category: number | null;
  }) {
    try {
      const response = await apiImprt.post('/api/CommunityAsset/getCommunityAssetItems', requestData);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error('API Error:', response);
        throw new Error(response.data?.Message || 'Failed to fetch community assets');
      }
    } catch (error) {
      console.error('Error in getCommunityAssetItems:', error);
      throw error;
    }
  }

  async getCommunityAssetItemById(assetId: string) {
    try {
      const response = await apiImprt.get(`/api/CommunityAsset/getCommunityAssetItemById?id=${assetId}`);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error('API Error:', response);
        throw new Error(response.data?.Message || 'Failed to fetch community asset');
      }
    } catch (error) {
      console.error('Error in getCommunityAssetItemById:', error);
      throw error;
    }
  }

  async getOtherCommunityAssetItemByCreator(params: { creatorId: string; currentAssetId: string }) {
    try {
      const response = await apiImprt.get(
        `/api/CommunityAsset/getOtherCommunityAssetItemByCreator`,
        {
          params: {
            creatorId: params.creatorId,
            currentAssetId: params.currentAssetId,
          },
        }
      );
      if (response.status === 200) {
        return response.data;
      } else {
        console.error('API Error:', response);
        throw new Error(response.data?.Message || 'Failed to fetch community assets');
      }
    } catch (error) {
      console.error('Error in getOtherCommunityAssetItemByCreator:', error);
      throw error;
    }
  }

  async getCommunityAssetProfileById(creatorId: string) {
    try {
      const response = await apiImprt.get(`/api/CommunityAsset/getCommunityAssetProfileById?id=${creatorId}`);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error('API Error:', response);
        throw new Error(response.data?.Message || 'Failed to fetch creator profile');
      }
    } catch (error) {
      console.error('Error in getCommunityAssetProfileById:', error);
      throw error;
    }
  }

  async getMarketItemsForWeb(userId: string, page: number = 1, pageSize: number = 10) {
    try {
      const response = await apiImprt.post(`/api/Market/getMarketItemsForWeb?userId=${userId}&page=${page}&pageSize=${pageSize}`);

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.data?.Message || 'Failed to fetch market items');
      }
    } catch (error) {
      console.error('Error in getMarketItemsForWeb:', error);
      throw error;
    }
  }

  async getWorldsForWeb(requestData: {
    PageNumber: number;
    PageSize: number;
    SearchText: string;
    PageType: number;
    WorldsSize: number;
    WorldSort: number;
    Descending: boolean;
    UserId?: string;
  }) {
    try {
      const response = await apiImprt.post('/api/Inventory/getWorldsForWeb', requestData);

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.data?.Message || 'Failed to fetch uploaded worlds');
      }
    } catch (error) {
      console.error('Error in getWorldsForWeb:', error);
      throw error;
    }
  }

  async getStorageSizeForCreator(): Promise<number> {
    try {
      const response = await apiImprt.get('/api/CommunityAsset/getStorageSizeForCreator');
      if (response.status === 200) {
        return response.data || 0;
      } else {
        console.error('API Error:', response);
        throw new Error(response.data?.Message || 'Failed to fetch storage size');
      }
    } catch (error) {
      console.error('Error in getStorageSizeForCreator:', error);
      throw error;
    }
  }

  async addAsset(assetData: {
    MainImageType: string;
    Name: string;
    Description: string;
    Nsfw: boolean;
    Category: number;
    FileType: string;
    FileContents: string;
    Tag: string;
    ImagesTypes: string;
  }) {
    try {
      const response = await apiImprt.post('/api/CommunityAsset/addAsset', assetData);
      if (response.status !== 200) {
        throw new Error(
          response.data?.Description || response.data?.Title || 'Unknown Error'
        );
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async addDownloadsCount(assetId: string) {
    try {
      const response = await apiImprt.post(`/api/CommunityAsset/addDownloadsCount?id=${assetId}`);
      if (response.status !== 200) {
        throw new Error(response.data?.Message || 'Failed to change download count');
      }
      console.log(`Downloads count changed for asset ID: ${assetId}`);
    } catch (error) {
      console.error('Error in addDownloadsCount:', error);
      throw error;
    }
  }

  async addRating(id: string, stars: number) {
    try {
      const response = await apiImprt.post(`/api/CommunityAsset/addRating?id=${id}&stars=${stars}`);
      if (response.status !== 200) {
        throw new Error(response.data?.Message || 'Failed to add rating');
      }
      console.log(`Rating added successfully for asset ID: ${id}`);
      return response.data;
    } catch (error) {
      console.error('Error in addRating:', error);
      throw error;
    }
  }

  async setFavorite(id: string, favorite: boolean) {
    try {
      const response = await apiImprt.post(`/api/CommunityAsset/setFavorite?id=${id}&favorite=${favorite}`);
      if (response.status === 200) {
        console.log(`Asset ${id} favorite state updated to ${favorite}.`);
        return response.data;
      } else {
        throw new Error(response.data?.Message || 'Failed to update favorite state');
      }
    } catch (error) {
      console.error('Error in setFavorite:', error);
      throw error;
    }
  }

  async addLike(userId: string) {
    try {
      const response = await apiIdentity.post(`/api/Karma/addLike?userId=${userId}`);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.data?.Message || 'Failed to add like');
      }
    } catch (error) {
      console.error('Error in addLike:', error);
      throw error;
    }
  }

  async addDislike(userId: string) {
    try {
      const response = await apiIdentity.post(`/api/Karma/addDislike?userId=${userId}`);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.data?.Message || 'Failed to add dislike');
      }
    } catch (error) {
      console.error('Error in addDislike:', error);
      throw error;
    }
  }

  async sendFriendRequest(friendId: string) {
    try {
      const response = await apiIdentity.post(`/api/Friend/sendFriendRequest?friendId=${friendId}`);
      if (response.status === 200) {
        return true;
      } else {
        throw new Error(response.data?.Message || 'Failed to send friend request');
      }
    } catch (error) {
      console.error('Error sending sendFriendRequest:', error);
      return false;
    }
  }

  async removeFriendRequestWeb(toUserId: string) {
    try {
      const response = await apiIdentity.delete(`/api/Friend/removeFriendRequestWeb?toUserId=${toUserId}`);
      if (response.status === 200) {
        return true;
      } else {
        throw new Error(response.data?.Message || 'Failed to remove friend request');
      }
    } catch (error) {
      console.error('Error in removeFriendRequestWeb:', error);
      return false;
    }
  }

  async removeFriend(friendId: string) {
    try {
      const response = await apiIdentity.delete(`/api/Friend/removeFriend?friendId=${friendId}`);
      if (response.status === 200) {
        return true;
      } else {
        throw new Error(response.data?.Message || 'Failed to remove friend');
      }
    } catch (error) {
      console.error('Error in removeFriend:', error);
      return false;
    }
  }

  async getKarmaPointsForWeb(userId: string) {
    try {
      const response = await apiIdentity.get(`/api/Karma/getKarmaPointsForWeb?userId=${userId}`);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.data?.Message || 'Failed to fetch karma points');
      }
    } catch (error) {
      console.error('Error in getKarmaPointsForWeb:', error);
      throw error;
    }
  }

  async deleteAsset(assetId: string) {
    try {
      const response = await apiImprt.delete(`/api/CommunityAsset/deleteAsset?id=${assetId}`);
      if (response.status === 200) {
        console.log(`Asset with ID ${assetId} deleted successfully.`);
        return response.data;
      } else {
        throw new Error(response.data?.Message || 'Failed to delete asset');
      }
    } catch (error) {
      console.error('Error in deleteAsset:', error);
      throw error;
    }
  }

  async deleteAssetForDev(assetId: string) {
    try {
      const response = await apiImprt.delete(`/api/CommunityAsset/deleteAssetForDev?id=${assetId}`);
      if (response.status === 200) {
        console.log(`Asset with ID ${assetId} deleted successfully.`);
        return response.data;
      } else {
        throw new Error(response.data?.Message || 'Failed to delete asset');
      }
    } catch (error) {
      console.error('Error in deleteAssetForDev:', error);
      throw error;
    }
  }
}

export default new AssetsStore()
